import React from 'react'
import { Link } from 'gatsby'
import Menu from './menu'
import Logo from '../../assets/svg/arquetipo-logo.svg'

const Header = () => (
  <header>
    <Link to="/">
      <Logo className='logo'/>
    </Link>
    <Menu/>
  </header>
)

export default Header
