import imageUrlBuilder from '@sanity/image-url'

const sanityClient = require('@sanity/client');

export const builder = imageUrlBuilder(sanityClient({
  projectId: '525wp3rx',
  dataset: 'production',
  useCdn: true,
  withCredentials: true,
}))

export const subscribeToData = (projectId, callback) => {
  const client = sanityClient({
    projectId: '525wp3rx',
    dataset: 'production',
    useCdn: true,
    withCredentials: true,
  });
  const query = `*[_id == 'drafts.${projectId}']`;
  client
  .listen(query, {}, {includeResult: false})
  .subscribe(() => {
    clearTimeout();
    setTimeout(() => {
      callback();
    }, 500);
  });

};

export const fetchDataFromSanity = async (projectId, isDraft) => {
  const
    client = sanityClient({
      projectId: '525wp3rx',
      dataset: 'production',
      useCdn: true,
      withCredentials: isDraft,
    });

  const query = `*[_id == 'drafts.${projectId}']`;

  return client.fetch(query);
}