import React, { Component } from 'react'
import * as queryString from 'query-string'
import {subscribeToData, fetchDataFromSanity, builder} from '../helpers/sanityHelpers'
import HeaderP from '../components/layout/headerP';
import Footer from '../components/layout/footer';
import Image from '../components/image'
import ProjectInfoPreview from '../components/project/infoPreview'
import ProjectTitlePreview from '../components/project/titlePreview'

export class PreviewTemplate extends Component {
  state={
    isLoading: true,
    data: undefined
  }

  componentDidMount() {
    const
      query = queryString.parse(this.props.location.search),
      { projectId, isDraft } = query;

    this.fetchData();

    if (isDraft) {
      subscribeToData(projectId, this.fetchData);
    }

  }

  fetchData = async () => {
    const
      query = queryString.parse(this.props.location.search),
      { projectId, isDraft } = query;

    fetchDataFromSanity(projectId, isDraft)
      .then(res => this.setState({
        isLoading: false,
        data: res[0]
      }))
      .catch(() => this.setState({isLoading:false}))

  };

  urlFor = (source) => {
    return builder.image(source)
  }

  render() {
    const
      {isLoading, data} = this.state;

    if (isLoading && !data) {

      return (
        <div id="success">
          <h1>LOADING</h1>
          <p>Please, wait...</p>
        </div>
      )

    } else {
      const
        { firstRow, secondRow, thirdRow, fourthRow, insideCover, title } = this.state.data,
        { year, location, surface, address, _rawConcept, pieces, available } = firstRow.info;

      return(
        <>
          <HeaderP/>
            <div className="project-inside grid" style={{paddingTop:"62px"}}>
              <Image className='cover' fluid={{src: this.urlFor(insideCover).url()}}/>
              <ProjectTitlePreview title={title}/>
              <div className='first-image'>
                <Image className='first-image' fluid={{src: this.urlFor(firstRow.image).url()}}/>
              </div>
              <ProjectInfoPreview
                year={year}
                location={location}
                surface={surface}
                address={address}
                concept={_rawConcept}
                pieces={pieces}
                available={available}
              />
              <div className='second-image'>
                <Image fluid={{src: this.urlFor(secondRow.image).url()}}/>
              </div>

              <div className='third-image'>
                <Image fluid={{src: this.urlFor(thirdRow.image).url()}}/>
              </div>
              <div className='fourth-image'>
                <Image fluid={{src: this.urlFor(thirdRow.smallImage).url()}}/>
              </div>

              <div className='fifth-image'>
                <Image fluid={{src: this.urlFor(fourthRow.smallImage).url()}} />
              </div>
              <div className='sixth-image'>
                <Image fluid={{src: this.urlFor(fourthRow.image).url()}}/>
              </div>
            </div>
          <Footer/>
        </>
      )
    }
  }
}

export default PreviewTemplate
