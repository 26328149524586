import React from 'react'
import BlockContent from '../block-content'

const ProjectInfo = ({ year, location, surface, address, concept, available, pieces }) => (
  <div className='info-container grid-4'>
    {!!year &&
      <div className='year'>
        <p>Year</p>
        <h4>{year}</h4>
      </div>
    }
    {!!location &&
      <div className='location'>
        <p>Location</p>
        <h4>{location}</h4>
      </div>
    }
    {!!surface &&
      <div className='surface'>
        <p>Surface</p>
        <h4>{surface} m<sup>2</sup></h4>
      </div>
    }
    {!!address &&
      <div className='address'>
        <p>Address</p>
        <h4>{address}</h4>
      </div>
    }
    {!!concept &&
      <div className='concept'>
        <p>Concept</p>
        <div className='block-container'>
          <BlockContent blocks={concept || []}/>
        </div>
      </div>
    }
    {!!pieces && pieces.length > 0 &&
      <div className='pieces'>
        <p>Pieces</p>
        <ul>
          {pieces.map((piece, i) => {
            return(
              <li key={i*500}>{piece}</li>
            )
          })}
        </ul>
      </div>
    }
    {!!available &&
      <p className='available'>Available <a href={available} target="__blank">here</a></p>
    }
  </div>
)

export default ProjectInfo
