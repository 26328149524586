import React from 'react'

const ProjectTitle = ({ title }) => (
  <div className='title-container grid'>
    <p>Project</p>
    <h1>{title}</h1>
  </div>
)

export default ProjectTitle
